import WorkspacePremiumIcon from "@mui/icons-material/WorkspacePremium";
import * as React from "react";
import {Card, Chip, Stack, Typography} from "@mui/joy";

interface VitaeSkill {
    name: string;
    icon?: string|undefined;
}

interface VitaeCertificate {
    name: string;
    icon?: string|undefined;
}

interface VitaeEntry {
    title: string;
    position?: string;
    partner?: string;
    location?: string;
    start: number;
    end?: number;
    skills: VitaeSkill[];
    certificates?: VitaeCertificate[];
}

interface VitaeThingyProps {
    entry: VitaeEntry
}

export default function VitaeThingy(props: VitaeThingyProps) {

    const entry = props.entry;

    return (
        <Card variant="soft">
            <div>
                <Typography level="h4">
                    {entry.title}
                </Typography>
                {
                    entry.position === undefined ? <></> :
                        <Typography>{entry.position}</Typography>
                }
                {
                    entry.location === undefined ? <></> :
                        <Typography>at {entry.location}</Typography>
                }
                {
                    entry.partner === undefined ? <></> :
                        <Typography>with {entry.partner}</Typography>
                }
                {
                    entry.end === undefined ?
                        <Typography>
                            since {entry.start}
                        </Typography> :
                        <Typography level="body-sm">
                            {entry.start} to {entry.end}
                        </Typography>
                }

                {entry.skills.map((skill, index) => {
                    return (
                        <Chip
                            variant="outlined"
                            startDecorator={
                                skill.icon ?
                                    <img
                                        height={24}
                                        width={24}
                                        src={"https://ceph-s3.alexware.systems/icons/icons8/fluency/icons8-" + skill.icon + "-48.png"}/>
                                    : undefined
                            }
                            style={{margin: '2px'}}
                        >
                            {skill.name}
                        </Chip>
                    )
                })}
                {
                    entry.certificates ?
                        <Stack direction="row" spacing={1} sx={{marginTop: '1em'}}>
                            {entry.certificates.map((certificate, index) => {
                                return <Chip
                                    variant={"outlined"}
                                    startDecorator={<WorkspacePremiumIcon/>}
                                    color="success"
                                >
                                    {certificate.name}
                                </Chip>
                            })}
                        </Stack> : <></>
                }
            </div>
        </Card>
    )
}