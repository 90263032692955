import React from "react";
import serverAnimation2 from "../../animations/lf30_editor_rzvyr1fe.json";
import Lottie from "lottie-react";
import {HomelabServiceItem} from "./HomelabServiceItem";
import {SystemSpecTable} from "./SystemSpecTable";
import {
    Accordion,
    AccordionDetails,
    AccordionSummary,
    Button,
    ButtonGroup,
    Grid,
    Stack,
    Typography
} from "@mui/joy";
import {SystemMetrics} from "./SystemMetrics";

export default function Homelab() {
    return <>
        <Stack spacing={2}>
            <Typography level="h1">Homelab</Typography>
            <ButtonGroup variant="soft" sx={{marginTop: '1rem'}}>
                <Button component={"a"} href={"#homelab_service"}>Services</Button>
                <Button component={"a"} href={"#homelab_system_specifications"}>System Specifications</Button>
                <Button component={"a"} href={"#homelab_realtime_metrics"}>Real Time Metrics</Button>
            </ButtonGroup>
            <Grid container>
                <Grid xs={6} md={4} padding={{md: '2rem'}}>
                    <Lottie
                        animationData={serverAnimation2}
                    />
                </Grid>
                <Grid xs={12} md={8}>
                    <Typography level="h2">
                        Overview
                    </Typography>
                    <Accordion>
                        <AccordionSummary
                            aria-controls="panel1a-content"
                            id="panel1a-header"
                        >
                            <Typography>Hardware</Typography>
                        </AccordionSummary>
                        <AccordionDetails>
                            A description of the hardware will follow soon...
                        </AccordionDetails>
                    </Accordion>
                    <Accordion>
                        <AccordionSummary
                            aria-controls="panel1a-content"
                            id="panel1a-header"
                        >
                            <Typography>Operating System</Typography>
                        </AccordionSummary>
                        <AccordionDetails>
                            <Typography>
                                Proxmox and Kubernetes (k3s).
                            </Typography>
                        </AccordionDetails>
                    </Accordion>
                </Grid>
            </Grid>

            <Typography level="h2" id={"homelab_services"}>
                Services
            </Typography>
            <Typography>
                Things I host in my homelab:
            </Typography>
            <Grid container>
                <Grid sm={12} md={12} lg={6}>
                    <Stack>
                        <HomelabServiceItem
                            icon="nextcloud"
                            icon_size={32}
                            name="Nextcloud"
                            description="Self hosted cloud service."
                        />
                        <HomelabServiceItem
                            icon="windows-95"
                            icon_size={32}
                            name="Active Directory"
                            description="AD for user management. Provides LDAP and RADIUS access as well as DNS."
                        />
                        <HomelabServiceItem
                            icon="https://raw.githubusercontent.com/element-hq/element-web/develop/res/themes/element/img/logos/element-logo.svg"
                            icon_size={32}
                            name="Matrix"
                            description="Communications"
                        />
                        <HomelabServiceItem
                            icon="https://ceph-s3.alexware.systems/awsys/icons/paperless.svg"
                            icon_size={32}
                            name="Paperless"
                            description="Digital documents"
                        />
                        <HomelabServiceItem
                            icon="https://ceph-s3.alexware.systems/awsys/icons/grafana.png"
                            icon_size={32}
                            name="Grafana"
                            description="Monitoring dashboards & alerting"
                        />
                        <HomelabServiceItem
                            icon="https://min.io/resources/img/logo/MINIO_Bird.png"
                            icon_size={32}
                            name="MinIO"
                            description="S3 Storage"
                        />
                        <HomelabServiceItem
                            icon="https://raw.githubusercontent.com/outline/outline/main/public/images/Icon-1024.png"
                            icon_size={32}
                            name="Outline"
                            description="Notes & documentation"
                        />
                    </Stack>
                </Grid>
                <Grid sm={12} md={12} lg={6}>
                    <Stack>
                        <HomelabServiceItem
                            icon="smart-home-automation"
                            icon_size={32}
                            name="Home Assistant"
                            description="Home Assistant for smart home automation such as thermostats and other sensors."
                        />
                        <HomelabServiceItem
                            icon="https://ceph-s3.alexware.systems/awsys/icons/109746326.png"
                            icon_size={32}
                            name="Immich"
                            description="Photo album"
                        />
                        <HomelabServiceItem
                            icon="https://ceph-s3.alexware.systems/awsys/icons/Vault-LogoMark_onDark.svg"
                            icon_size={32}
                            name="Hashicorp Vault"
                            description="Secrets management"
                        />
                        <HomelabServiceItem
                            icon="https://raw.githubusercontent.com/goauthentik/authentik/main/web/icons/icon.svg"
                            icon_size={32}
                            name="Authentik"
                            description="Single Sign On solution"
                        />
                        <HomelabServiceItem
                            icon="https://influxdata.github.io/branding/img/downloads/influxdata-logo--symbol--pool-alpha.png"
                            icon_size={32}
                            name="InfluxDB"
                            description="Time series database"
                        />
                        <HomelabServiceItem
                            icon="gitlab"
                            icon_size={48}
                            name="Gitlab"
                            description="Version control for source code and CI/CD pipelines."
                            href="https://gitlab.alexware.systems/explore"
                        />
                    </Stack>
                </Grid>
            </Grid>

            <Typography level="h2" id={"homelab_system_specifications"} sx={{paddingTop: 3}}>
                System Specifications
            </Typography>
            <SystemSpecTable/>

            <Typography level="h2" id={"homelab_realtime_metrics"} sx={{paddingTop: 3}}>
                Real time metrics
            </Typography>
            <SystemMetrics/>
        </Stack>
    </>
}