import * as React from "react"
import MasonGallery from "../components/gallery/MasonGallery";
import Footer from "../components/footer/Footer";
import AdditionalSkills from "../components/vitae/additional_skills";
import Homelab from "../components/homelab/Homelab";
import {Avatar, Button, ButtonGroup, Grid, Stack, Typography} from "@mui/joy";
import Vitae from "../components/vitae/vitae";
import {Portfolio} from "../components/portfolio/Portfolio";

const IndexPage = () => {
  return <>
      <Grid
          container
          direction="column"
          justifyContent="center"
          alignItems="center"
          style={{
              marginBottom: "5rem",
          }}
      >
          <Grid>
              <Stack direction="column" alignItems="center" sx={{marginTop: 4}}>
                  <Avatar
                      alt="Alexander Kehr"
                      src="https://ceph-s3.alexware.systems/awsys/kehr_casual.png"
                      sx={{width: 256, height: 256, marginTop: '1rem'}}
                  />
                  <Typography level="h3" sx={{marginTop: 4}}>
                      Alexander Kehr
                      <hr/>
                  </Typography>
                  <Stack direction={{xs: 'column', sm: 'row'}} alignItems="center">
                      <Typography level="h4">
                          DevOps Engineer
                      </Typography>
                      <Typography level="h4">
                          &nbsp;&&nbsp;
                      </Typography>
                      <Typography level="h4">
                          Cosplaying Sysadmin
                      </Typography>
                  </Stack>
                  <Typography level="body-sm" style={{fontStyle: 'italic'}}>
                      keeping things running
                  </Typography>
                  <Button
                      sx={{marginTop: "1em"}}
                      endDecorator={<img src="https://ceph-s3.alexware.systems/awsys/icons/DoubleArrow.png" height={16}/>}
                      color={"primary"}
                      component="a"
                      href="https://cloud.alexware.systems/apps/calendar/appointment/EkBdJ5c4rm9d"
                  >
                      Get in contact
                  </Button>
                  <ButtonGroup variant="soft" sx={{marginTop: '1rem'}}>
                      <Button component={"a"} href={"#timeline"}>Timeline</Button>
                      <Button component={"a"} href={"#additional_skills"}>Additional Skills</Button>
                      <Button component={"a"} href={"#homelab"}>Homelab</Button>
                      <Button component={"a"} href={"#gallery"}>Gallery</Button>
                  </ButtonGroup>
              </Stack>
          </Grid>
      </Grid>

      <Vitae/>
      <AdditionalSkills/>
      <Portfolio/>

      <div style={{height: '5rem'}}/>

      <Grid container justifyContent="center" id="homelab">
          <Grid xs={12} md={10} xl={8}>
              <Homelab/>
          </Grid>
      </Grid>

      {/*
        <div style={{height: '10rem'}}/>
        <Box style={{height: '500px'}}>
          <ArchitectureGraph/>
        </Box>
      */}

      <div style={{height: '5rem'}}/>

      <MasonGallery/>

      <div style={{height: '5rem'}}/>

      <Footer/>
      <div style={{height: '2rem'}}/>
  </>
}

export default IndexPage
