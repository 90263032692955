import React from "react";
import {Avatar, Button, ListItem} from "@mui/joy";
import {ListItemAvatar, ListItemText} from "@mui/material";

export interface HomelabServiceItemProps {
    icon: string;
    name: React.ReactNode;
    description: React.ReactNode;
    icon_size?: number;
    href?: string;
}

export const HomelabServiceItem : React.FC<HomelabServiceItemProps> = (
    {icon, name, description, icon_size, href}) => {
    return (
        <ListItem>
            <ListItemAvatar>
                <Avatar sx={{ bgcolor: '#0000000F' }}>
                    {icon.startsWith("https") ?
                        <img
                            height={icon_size ?? 24}
                            src={icon}
                        />
                        :
                        <img
                            height={icon_size ?? 24}
                            src={`https://ceph-s3.alexware.systems/icons/icons8/fluency/icons8-${icon}-48.png`}
                        />
                    }
                </Avatar>
            </ListItemAvatar>
            <ListItemText
                primary={name}
                secondary={href ?
                    <>
                        {description}
                        <br/>
                        <Button
                            style={{
                                marginTop: '0.5rem',
                            }}
                            variant="outlined"
                            component={"a"}
                            href={href}
                            size={"sm"}
                            endDecorator={<img
                                width={24}
                                height={24}
                                src="https://ceph-s3.alexware.systems/awsys/icons/DoubleArrow.png"
                            />}
                        >
                            Visit
                        </Button>
                    </>
                    : description }
            />
        </ListItem>
    )
}