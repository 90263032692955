import React from "react";
import {NodeClassChip} from "./NodeClassChip";
import {NetworkClassChip} from "./NetworkClassChip";
import {DiskChip} from "./DiskChip";
import {Chip, Sheet, Stack, Table, Typography} from "@mui/joy";
import {
    I8fBroadcasting,
    I8fMemorySlot,
    I8fProcessor,
    I8fSave
} from "../Icons8FluentIcon";

interface InodeData {
    name: string;
    color: {
        primary: string;
        secondary?: string;
    };
    class: string;
    has_gpu?: boolean;
    cpu: {
        vendor: string;
        family: string;
        arch: string;
        codename: string;
        model: string;
        cores: number;
        threads: number;
        clock: string;
    }
    memory: {
        size: string;
        class: string;
    }
    disk: {
        data: string;
        vm: string;
    }
    network: string;
}

const nodeData : InodeData[] = [
    {
        name: "Kingblue",
        color: {
            primary: "#3465a4"
        },
        class: "performance",
        cpu: {
            vendor: "AMD",
            arch: "x86_64",
            family: "Ryzen 5",
            codename: "Pinnacle Ridge",
            model: "2600",
            cores: 6,
            threads: 12,
            clock: "3.4GHz"
        },
        memory: {
            size: '32GB',
            class: "DDR4"
        },
        disk: {
            data: "96TB",
            vm: "1TB"
        },
        network: "10gbit",
        has_gpu: true
    },
    {
        name: "Grapegreen",
        color: {
            primary: "#4e9a06"
        },
        class: "performance",
        cpu: {
            vendor: "AMD",
            arch: "x86_64",
            family: "Ryzen 5",
            codename: "Pinnacle Ridge",
            model: "2600",
            cores: 6,
            threads: 12,
            clock: "3.4GHz"
        },
        memory: {
            size: '64GB',
            class: "DDR4"
        },
        disk: {
            data: "3TB",
            vm: "1TB"
        },
        network: "10gbit",
        has_gpu: true
    },
    {
        name: "Honeyyellow",
        color: {
            primary: "#fce94f",
            secondary: "black"
        },
        class: "efficiency",
        cpu: {
            vendor: "Intel",
            arch: "x86_64",
            family: "Celeron",
            codename: "Gemini Lake Refresh",
            model: "J4125",
            cores: 4,
            threads: 4,
            clock: "2.0GHz"
        },
        memory: {
            size: '32GB',
            class: "DDR4"
        },
        disk: {
            data: "3TB",
            vm: "1TB"
        },
        network: "10gbit"
    },
    {
        name: "Papapink",
        color: {
            primary: "#ad7fa8",
            secondary: "white"
        },
        class: "efficiency",
        cpu: {
            vendor: "Intel",
            arch: "x86_64",
            family: "Celeron",
            codename: "Gemini Lake",
            model: "J4105",
            cores: 4,
            threads: 4,
            clock: "1.5GHz"
        },
        memory: {
            size: '32GB',
            class: "DDR4"
        },
        disk: {
            data: "3TB",
            vm: "1TB"
        },
        network: "10gbit"
    },
    {
        name: "Brickblack",
        color: {
            primary: "black"
        },
        class: "performance",
        cpu: {
            vendor: "AMD",
            arch: "x86_64",
            family: "Ryzen 5",
            codename: "Vermeer",
            model: "5600X",
            cores: 6,
            threads: 12,
            clock: "3.7GHz"
        },
        memory: {
            size: '32GB',
            class: "DDR4"
        },
        disk: {
            data: "3TB",
            vm: "1TB"
        },
        network: "10gbit",
    }
]

export const SystemSpecTable: React.FC = () => {
    return <>
        <Sheet>
            <Table sx={{ minWidth: 650 }} aria-label="System specifications table">
                <thead>
                    <tr>
                        <th>Node Name</th>
                        <th align="right">
                            Node Classification
                        </th>
                        <th align="right">
                            <Typography startDecorator={<I8fProcessor size={24}/>}>
                                CPU
                            </Typography>
                        </th>
                        <th align="right">
                            <Typography startDecorator={<I8fMemorySlot size={24}/>}>
                                Memory
                            </Typography>
                        </th>
                        <th align="right">
                            <Typography startDecorator={<I8fSave size={24}/>}>
                                Disk Space
                            </Typography>
                        </th>
                        <th align="right">
                            <Typography startDecorator={<I8fBroadcasting size={24}/>}>
                                Networking
                            </Typography>
                        </th>
                    </tr>
                </thead>
                <tbody>
                    {nodeData.map(system => (
                        <tr key={system.name}>
                            <td>
                                <Chip
                                    style={{
                                        backgroundColor: system.color.primary,
                                        color: system.color.secondary ?? 'white'
                                    }}
                                >
                                    {system.name}
                                </Chip>
                            </td>
                            <td align="right">
                                <Stack direction="column" spacing={0.5} alignItems="start">
                                    <NodeClassChip nodeClass={system.class}/>
                                    { system.has_gpu ?
                                        <>
                                            <br/>
                                            <Chip
                                                startDecorator={
                                                    <img
                                                        height={24}
                                                        src={`https://ceph-s3.alexware.systems/icons/icons8/fluency/icons8-nvidia-48.png`}
                                                    />
                                                }
                                            >
                                                {"GPU compute"}
                                            </Chip>
                                        </>
                                        : <></>
                                    }
                                    </Stack>
                            </td>
                            <td align="right">
                                {system.cpu.vendor} {system.cpu.family} {system.cpu.model}
                                <br/>
                                <small>{system.cpu.codename}</small>
                                <br/>
                                <small>{system.cpu.cores} Cores @ {system.cpu.clock}</small>
                            </td>
                            <td align="right">
                                <Stack direction="column" spacing={0.5} alignItems="center">
                                    <Typography>{system.memory.size} ({system.memory.class})</Typography>
                                </Stack>
                            </td>
                            <td align="right">
                                <Stack direction="column" spacing={0.5} alignItems="center">
                                    <DiskChip size={system.disk.data} diskClass={"hdd"}/>
                                    <DiskChip size={system.disk.vm} diskClass={"m.2"}/>
                                </Stack>
                            </td>
                            <td align="right">
                                <Stack direction="column" spacing={0.5} alignItems="center">
                                    <NetworkClassChip networkClass={system.network}/>
                                </Stack>
                            </td>
                        </tr>
                    ))}
                </tbody>
            </Table>
        </Sheet>
    </>
}