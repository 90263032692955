import * as React from "react";
import VacancyMap from "../components/vacancy/Map";
import RequirementItem from "../components/vacancy/RequirementItem";
import {Button, ButtonGroup, Card, Chip, Grid, Link, List, Stack, Typography} from "@mui/joy";

const skills: any[] = [
    {name:'Ansible', icon: 'ansible'},
    {name:'GitLab CI/CD', icon: 'gitlab'},
    {name:'Python', icon: 'python'},
]

const fields: any[] = [
    {name:'Kubernetes', icon: 'kubernetes'},
    {name:'Docker', icon: 'docker'},
    {name:'Proxmox VE', icon: 'proxmox'},
    {name:'Linux', icon: 'fedora'},
]

const VacancyPage = ()  => {
    return (
        <>
            <Grid
                container
                spacing={2}
                sx={{padding: 2}}
                justifyContent="center"
                alignItems="stretch"
            >
                <Grid xs={12} sm={12} md={6} sx={{maxWidth: '50em'}}>
                    <Card variant={"soft"}>
                        <Typography level="h4" style={{marginBottom: "1rem"}}>
                            Preferred Area
                        </Typography>
                        <Typography>
                            {"Preferred distance to next office location (if less than 100% remote)."}
                        </Typography>
                        <VacancyMap />
                    </Card>
                </Grid>
                <Grid sm={12} md={6} sx={{maxWidth: '50em'}}>
                    <Stack spacing={2}>
                        <Card variant={"soft"}>
                            <Typography level="h4" style={{marginBottom: "1rem"}}>
                                Key Information and Restrictions
                            </Typography>
                            <Grid container>
                                <Grid sm={12} md={12} lg={6}>
                                    <List>
                                        <RequirementItem
                                            icon={"person"}
                                            primary={"Role"}
                                            secondary={<>(Senior) DevOps <br/>
                                                &nbsp;&nbsp;&nbsp;&nbsp;or Linux Systems Administrator</>}
                                        />
                                        <RequirementItem icon={"münzen"} primary={"Salary/Year"} secondary={"o.n.o. 70.000€"}/>
                                        <RequirementItem
                                            icon={"arbeiten-mit-einem-laptop"}
                                            primary={"Remote Working"}
                                            secondary={<>3 to 4 Days / Week<br/>
                                                &nbsp;&nbsp;&nbsp;&nbsp;(60 to 80% or 100% remote)</>}
                                        />
                                    </List>
                                </Grid>
                                <Grid sm={12} md={12} lg={6}>
                                    <List>
                                        <RequirementItem icon={"zeitspanne"} primary={"Hours/Week"} secondary={"~40h"}/>
                                        <RequirementItem icon={"sonnen"} primary={"Vacation/Year"} secondary={"~30 Days"}/>
                                        <RequirementItem icon={"flugzeug-abflug"} primary={"Travel"} secondary={"None to occasionally"}/>
                                    </List>
                                </Grid>

                            </Grid>
                            <Typography>
                                Optional
                            </Typography>
                            <List>
                                <RequirementItem icon={"laptop"} primary={"Choose your own Device/OS"}/>
                            </List>
                        </Card>

                        <Card variant={"soft"}>
                            <Typography level="h4" style={{marginBottom: "1rem"}}>
                                Core Technologies
                            </Typography>
                            <Stack direction={"row"}>
                                {skills.map((skill, index) => {
                                    return <Chip
                                        key={index}
                                        startDecorator={
                                            skill.icon ?
                                                <img
                                                    height={24}
                                                    width={24}
                                                    src={"https://ceph-s3.alexware.systems/icons/icons8/fluency/icons8-" + skill.icon + "-48.png"}/>
                                                : undefined
                                        }
                                        style={{margin: '2px'}}>
                                        {skill.name}
                                    </Chip>
                                })}
                            </Stack>
                        </Card>

                        <Card variant={"soft"}>
                            <Typography level="h4" style={{marginBottom: "1rem"}}>
                                Core Fields
                            </Typography>
                            <Stack direction={"row"}>
                                {fields.map((skill, index) => {
                                    return <Chip
                                        startDecorator={
                                            skill.icon ?
                                                <img
                                                    height={24}
                                                    width={24}
                                                    src={"https://ceph-s3.alexware.systems/icons/icons8/fluency/icons8-" + skill.icon + "-48.png"}/>
                                                : undefined
                                        }
                                        style={{margin: '2px'}}>
                                        {skill.name}
                                    </Chip>
                                })}
                            </Stack>
                        </Card>

                        <Card variant={"soft"}>
                            <Typography level="h4" style={{marginBottom: "1rem"}}>
                                Documents
                            </Typography>
                            <Button
                                startDecorator={
                                    <img height={24}
                                         width={24}
                                         src={"https://ceph-s3.alexware.systems/icons/icons8/fluency/icons8-seiten%C3%BCbersicht-2-48.png"}/>
                                }
                                component={"a"}
                                download='AlexanderKehr_CV.pdf'
                                href={"https://ceph-s3.alexware.systems/awsys/documents/alexanderkehr/clw5a2muh066no3vhpxurerxu.pdf"}
                            >
                                Curriculum Vitae
                            </Button>
                            <Button
                                variant={"soft"}
                                startDecorator={
                                    <img height={24}
                                         width={24}
                                         src={"https://ceph-s3.alexware.systems/icons/icons8/fluency/icons8-zertifizierung-48.png"}/>
                                }
                                component={"a"}
                                download='AlexanderKehr_OTC_Architect.pdf'
                                href={"https://ceph-s3.alexware.systems/awsys/documents/alexanderkehr/f%C3%B634c89dfhcamod4.pdf"}
                            >
                                Open Telekom Cloud Architect Certificate
                            </Button>
                            <Button
                                variant={"soft"}
                                startDecorator={
                                    <img height={24}
                                         width={24}
                                         src={"https://ceph-s3.alexware.systems/icons/icons8/fluency/icons8-diplom-48.png"}/>
                                }
                                component={"a"}
                                download='AlexanderKehr_Abschlusszeugnis.pdf'
                                href={"https://ceph-s3.alexware.systems/awsys/documents/alexanderkehr/d7924ondm7o982cd73AOCDG7HN23XC8i742q.pdf"}
                            >
                                Hochschule Darmstadt Abschlusszeugnis
                            </Button>
                        </Card>

                        <Card variant={"soft"}>
                            <Typography level="h4" style={{marginBottom: "1rem"}}>
                                Contact Information
                            </Typography>
                            <ButtonGroup sx={{marginBottom: 2}}>
                                <Button
                                    startDecorator={
                                        <img height={24}
                                             width={24}
                                             src={"https://ceph-s3.alexware.systems/icons/icons8/fluency/icons8-web-design-48.png"}/>
                                    }
                                    component={"a"}
                                    href={"https://www.alexware.systems/"}
                                >
                                    Homepage
                                </Button>
                                <Button
                                    startDecorator={
                                        <img height={24}
                                             width={24}
                                             src={"https://ceph-s3.alexware.systems/icons/icons8/fluency/icons8-xing-48.png"}/>
                                    }
                                    component={"a"}
                                    href={"https://www.xing.com/profile/Alexander_Kehr6/"}
                                >
                                    Xing
                                </Button>
                                <Button
                                    startDecorator={
                                        <img height={24}
                                             width={24}
                                             src={"https://ceph-s3.alexware.systems/icons/icons8/fluency/icons8-linkedin-48.png"}/>
                                    }
                                    component={"a"}
                                    href={"https://www.linkedin.com/in/alexander-kehr-60ba33225/"}
                                >
                                    LinkedIn
                                </Button>
                            </ButtonGroup>
                            <Typography level="body-sm" gutterBottom>
                                <Stack direction="row" spacing={2}>
                                    <img height={24}
                                         width={24}
                                         src={"https://ceph-s3.alexware.systems/icons/icons8/fluency/icons8-nachricht-48.png"}/>
                                    <Link href="mailto:alexanderkehr@alexware.systems" underline="none">
                                        alexanderkehr@alexware.systems
                                    </Link>
                                </Stack>
                                <Stack direction="row" spacing={2}>
                                    <img height={24}
                                         width={24}
                                         src={"https://ceph-s3.alexware.systems/icons/icons8/fluency/icons8-telefon-48.png"}/>
                                    <Link href="tel:+491622626294" underline="none">
                                        +49 162 2626294
                                    </Link>
                                </Stack>
                                <Stack direction="row" spacing={2}>
                                    <img height={24}
                                         width={24}
                                         src={"https://ceph-s3.alexware.systems/icons/icons8/fluency/icons8-matrix-48.png"}/>
                                    <Link href="https://matrix.to/#/@alexanderkehr:alexware.systems" underline="none">
                                        @alexanderkehr:alexware.systems
                                    </Link>
                                </Stack>
                            </Typography>
                            <Button
                                variant={"soft"}
                                startDecorator={
                                    <img height={24}
                                         width={24}
                                         src={"https://ceph-s3.alexware.systems/icons/icons8/fluency/icons8-videokonferenz-48.png"}/>
                                }
                                component={"a"}
                                href={"https://cloud.alexware.systems/apps/calendar/appointment/EkBdJ5c4rm9d"}
                            >
                                Get in contact with a video conference
                            </Button>
                        </Card>

                    </Stack>
                </Grid>
            </Grid>
        </>
    )
}

export default VacancyPage