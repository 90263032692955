import React from "react";
import {Chip} from "@mui/joy";

interface NodeClassChipPops {
    nodeClass: string;
}

export const NodeClassChip: React.FC<NodeClassChipPops> = ({nodeClass}) => {

    let icon: string;

    switch (nodeClass) {
        case "efficiency":
            icon = "blatt"
            break;
        case "performance":
            icon = "tacho";
            break;
        default:
            icon = "offline"
            break;
    }

    const image_url = `https://ceph-s3.alexware.systems/icons/icons8/fluency/icons8-${icon}-48.png`

    return <>
        <Chip
            startDecorator={
                <img
                    height={24}
                    src={image_url}
                />
            }
        >
            {nodeClass}
        </Chip>
    </>
}